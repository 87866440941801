






























import { Component, Vue } from 'vue-property-decorator'
import Card from '@/components/Card/Card.vue';
import { ProfileService } from '@/services/ProfileService';
import PhraseLink  from '@/components/PhraseLink/PhraseLink.vue';


@Component({ 
  components:{ 
    Card, 
    PhraseLink
  }
})
export default class NotAvailable extends Vue {
  store=ProfileService
}
